import React from 'react'
import styled,{createGlobalStyle} from 'styled-components'
import Seo from '../components/Seo';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300;1,400&display=swap');
  body {
    margin: 0;
  }`;

const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto", sans-serif;
  background-color: #212223;
  color: white;
  font-size: 120px;
  font-weight: 400;`;

const Text = styled.div`
  font-family: "Roboto", sans-serif;
  font-size: ${props=> props.nr === "1"? "7rem": "3.3rem"};`;

export default function NotFound() {
  return (
    <div>
    <GlobalStyle/>
    <Seo title="Not Found!"/>
    <Container>
      <Text nr="1">404</Text>
      <Text nr="2">Not Found!</Text>
    </Container>
    </div>
  )
}
